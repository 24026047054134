<template>
    <div class="p20 bsbb">
        <van-nav-bar left-arrow @click-left="onClickLeft">
            <template #title>
                <div class="top-nav df aic jcsb">
                    <div class="df aic jcc">
                        <span style="font-weight: bold; font-size: 20px; line-height: normal">地址管理</span>
                    </div>
                </div>
            </template>
        </van-nav-bar>
        <div style="padding: 15px">
            <!-- <van-address-list v-model="chosenAddressId" :list="list" default-tag-text="默认" @add="onAdd" @edit="onEdit">
                <template #tag>

                </template>
            </van-address-list> -->
            <van-radio-group v-model="checked">
                <div v-for="(v, index) in list" :key="index" style="width: 100%; padding: 10px 0px 10px 0px" class="addcrd">
                    <van-swipe-cell>
                        <van-row>
                            <van-col span="20" style="margin-left: 10px">
                                <van-radio :name="v.id" checked-color="rgba(55, 159, 0, 1)" icon-size="24px"
                                    @click="sets(v)">
                                    <p class="pstysize">{{ v.address }}</p>
                                    <p class="pstysize">
                                        <span>{{ v.name }}</span><span style="margin-left: 10px">{{ v.tel }}</span>
                                        <span v-if="v.isDefault == true"
                                            style="color: white; background: red; border-radius: 10px; font-size: 12px; margin-left: 10px;">
                                            默认
                                        </span>
                                    </p>
                                </van-radio>
                            </van-col>
                            <van-col span="3" style="
                                                                                                                      display: flex;
                                                                                                                      justify-content: center;
                                                                                                                      align-items: center;
                                                                                                                    ">
                                <img src="../../assets/images/xg/addcrs.png" alt="" style="width: 20px"
                                    @click="letShow(v.id)" />
                            </van-col>
                        </van-row>
                        <template #right>
                            <van-button square type="danger" text="删除" @click="delShipAddresss(v.id)" />
                        </template>
                    </van-swipe-cell>
                </div>
            </van-radio-group>
        </div>
        <div style="width: 100%; height: 50px" />
        <div class="addbutten">
            <van-button color="#1a90c0" style="width: 90%; height: 50px; border-radius: 50px; font-size: 32px"
                @click="onAdd">
                新增地址
            </van-button>
        </div>
        <van-popup v-model:show="show" position="bottom" closeable :style="{ height: '55%' }" @close="close">
            <div style="width: 100%">
                <span
                    style="font-size: 16px; position: absolute; top: 0px;  margin-top: 10px; left: 50%; transform: translateX(-50%);">添加地址</span>
            </div>
            <div style="margin-top: 30px; text-align: center">
                <van-cell-group inset>
                    <van-field v-model="adddata.username" label="姓名" />
                    <van-field v-model="adddata.mobile" type="tel" label="手機號" />
                    <van-field v-model="adddata.merchant_address_sheng" label="省" />
                    <van-field v-model="adddata.merchant_address_shi" label="市" />
                    <van-field v-model="adddata.merchant_address_qu" label="区" />
                    <van-field v-model="adddata.merchant_address_dizhi" label="詳細地址" />
                    <van-cell center title="是否設置為默認地址">
                        <template #right-icon>
                            <van-switch v-model="address_default" size="24" @change="asd" />
                        </template>
                    </van-cell>
                </van-cell-group>
                <van-button v-if="truese == false" type="danger" style="
                                                                                                                width: 90%;
                                                                                                                height: 40px;
                                                                                                                font-size: 16px;
                                                                                                                margin-bottom: 10px;
                                                                                                                border-radius: 15px;
                                                                                                              "
                    @click="adddatas">確認添加</van-button>
                <van-button v-else type="danger" style="
                                                                                                                width: 90%;
                                                                                                                height: 40px;
                                                                                                                font-size: 16px;
                                                                                                                margin-bottom: 10px;
                                                                                                                border-radius: 15px;
                                                                                                              "
                    @click="editdatas">確認修改</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script setup>
import { ref, reactive, watch } from "vue";
import { Toast, Dialog } from "vant";
import {
    ShipAddress,
    listShipAddress,
    delShipAddress,
    upShipAddress,
} from "@/utils/api.js";
import { areaList } from "@vant/area-data";
import { useRouter } from 'vue-router'
const $router = useRouter()
const chosenAddressId = ref();
const list = ref([]);
const editlist = ref([]);
const id = ref();
// :disabled-list="disabledList" disabled-text="以下地址超出配送范围" 超出范围配置
// const disabledList = [
//     {
//         id: '3',
//         name: '王五',
//         tel: '1320000000',
//         address: '浙江省杭州市滨江区江南大道 15 号',
//     },
// ];
const show = ref(false);
// 填充编辑地址弹框
function letShow(n) {
    console.log(n);
    adddata.value = editlist.value.find((obj) => {
        return obj.id == n;
    });
    truese.value = true;
    id.value = n;
    console.log(adddata.value);
    address_default.value = adddata.value.address_default === 10;
    onAdd();
}
// 滑块状态
const address_default = ref(false);
function asd() {
    console.log(address_default.value, "ll");
}
function sets(data) {
    console.log(data);
    localStorage.setItem("stesadd", JSON.stringify(data));
    Dialog.confirm({
        title: '选择收获地址',
        message:
            '确定选择此收获地址？',
    })
        .then(() => {
            // on confirm
            localStorage.setItem('addressId', data.id)
            onClickLeft()
        })
        .catch(() => {
            // on cancel
        });
}
const checked = ref("");
// 获取地址列表
getMhList()
function getMhList() {
    listShipAddress({}).then((res) => {
        editlist.value = res.data.list;
        list.value = res.data.list.map((item) => ({
            id: item.id,
            name: item.username,
            tel: item.mobile,
            address:
                item.merchant_address_sheng +
                item.merchant_address_shi +
                item.merchant_address_qu +
                item.merchant_address_dizhi,
            isDefault: item.address_default === 10,
        }));
        checked.value = list.value[0].id;
        localStorage.setItem("stesadd", JSON.stringify(list.value[0]))
    });
}
// console.log(JSON.parse(localStorage.getItem("stesadd")).id);

// checked.value = JSON.parse(localStorage.getItem("stesadd")).id
if (localStorage.getItem("stesadd")) {
    checked.value = JSON.parse(localStorage.getItem("stesadd")).id
}
// 关闭弹窗
const onAdd = () => {
    show.value = !show.value;
    console.log(show.value, 111);
    // if (show.value == true) {
    //     show.value = false
    // }else{
    //     show.value = true
    // }
};
// 关闭地址弹框
function close() {
    adddata.value = {
        username: "",
        mobile: "",
        merchant_address_sheng: "",
        merchant_address_shi: "",
        merchant_address_qu: "",
        merchant_address_dizhi: "",
        address_default: "",
    };
    show.value = false;
    // show.value = !show.value;
    truese.value = false;
}
function cheng(event) {
    console.log(event);
}
const onEdit = (item, index) => {
    console.log(1111);
};

const adddata = ref({
    username: "",
    mobile: "",
    merchant_address_sheng: "",
    merchant_address_shi: "",
    merchant_address_qu: "",
    merchant_address_dizhi: "",
    address_default: false,
});
const adddataasd = ref(adddata.address_default === 10);
// 新增地址
const truese = ref(false);
var flag = true;
function adddatas() {
    for (var key in adddata.value) {
        if (!adddata.value[key] && key !== "address_default") {
            console.log(adddata.value, "qqqq");
            flag = false;
            return Toast({
                message: "请填写完整",
                icon: "fail",
                className: "noticeWidth",
            });
        }
    }
    if (flag) {
        ShipAddress({
            username: adddata.value.username,
            mobile: adddata.value.mobile,
            merchant_address_sheng: adddata.value.merchant_address_sheng,
            merchant_address_shi: adddata.value.merchant_address_shi,
            merchant_address_qu: adddata.value.merchant_address_qu,
            merchant_address_dizhi: adddata.value.merchant_address_dizhi,
            address_default: address_default.value === true ? 10 : 20,
        }).then((res) => {
            if (res.code == 200) {
                // onAdd();
                show.value = false;
                // adddata.value = {
                //   username: "",
                //   mobile: "",
                //   merchant_address_sheng: "",
                //   merchant_address_shi: "",
                //   merchant_address_qu: "",
                //   merchant_address_dizhi: "",
                //   address_default: "",
                // };
                Toast({
                    message: "添加成功",
                    icon: "success",
                    className: "noticeWidth",
                });
                getMhList()
            } else {
                Toast({
                    message: "添加失败",
                    icon: "fail",
                    className: "noticeWidth",
                });
            }
        });
    }
}
// 删除地址
function delShipAddresss(id) {
    delShipAddress({ id: id }).then((res) => {
        console.log(res);
        Toast({
            message: "删除成功",
            icon: "success",
            className: "noticeWidth",
        });
        getMhList();
    });
}
// 修改地址
function editdatas() {
    upShipAddress({
        id: id.value,
        username: adddata.value.username,
        mobile: adddata.value.mobile,
        merchant_address_sheng: adddata.value.merchant_address_sheng,
        merchant_address_shi: adddata.value.merchant_address_shi,
        merchant_address_qu: adddata.value.merchant_address_qu,
        merchant_address_dizhi: adddata.value.merchant_address_dizhi,
        address_default: address_default.value === true ? 10 : 20,
    }).then((res) => {
        if (res.code == 200) {
            adddata.value = {
                username: "",
                mobile: "",
                merchant_address_sheng: "",
                merchant_address_shi: "",
                merchant_address_qu: "",
                merchant_address_dizhi: "",
                address_default: "",
            };
            Toast({
                message: "修改成功",
                icon: "success",
                className: "noticeWidth",
            });
            getMhList()
            onAdd();
        } else {
            Toast({
                message: "修改失败",
                icon: "fail",
                className: "noticeWidth",
            });
        }
    });
}
const onClickLeft = () => history.back();

// const page = ref(1)
// const page_size = ref(50)
// const detailedlist = ref([])
// const time = ref([])
// const times = ref([])
// function getlist(pagnub) {
//     detailed({ page: pagnub, page_size: page_size.value }).then(async (res) => {
//         if ((await res.code) === 200) {
//             console.log(res, 'rr');
//             detailedlist.value = res.data.list
//             // detailedlist.value.create_time.forEach((v) => {
//             //   time.value.push(v);
//             //   });
//             console.log(time.value);
//         }
//     });
// }
// // times.value = formatDate(time)
// const formatDate = (value, spe = '/') => {
//     value = value * 1000
//     let data = new Date(value)
//     let year = data.getFullYear()
//     let month = data.getMonth() + 1
//     let day = data.getDate()
//     let h = data.getHours()
//     let mm = data.getMinutes()
//     let s = data.getSeconds()
//     month = month >= 10 ? month : '0' + month
//     day = day >= 10 ? day : '0' + day
//     h = h >= 10 ? h : '0' + h
//     mm = mm >= 10 ? mm : '0' + mm
//     s = s >= 10 ? s : '0' + s
//     return `${year}${spe}${month}${spe}${day} ${h}:${mm}:${s}`
// }
</script>

<style lang="scss" scoped>
::v-deep .van-icon {
    font-size: 32px;
}

::v-deep .van-nav-bar__arrow {
    font-size: 46px !important;
    color: #000;
}

::v-deep .van-nav-bar__content {
    height: 70px;
    line-height: 70px;
    box-shadow: none !important;
}

.addcrd {
    border-bottom: 1px rgba(173, 173, 173, 1) solid;
}

.addbutten {
    width: 100%;
    position: fixed;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pstysize {
    font-size: 32px;
}
</style>
<style lang="scss">
.noticeWidth {
    width: 30% !important;
    font-size: 32px !important;
}

.van-nav-bar__content {
    height: 1rem !important;
}

.van-nav-bar__arrow {
    font-size: 40px !important;
}

.van-dropdown-menu__bar {
    box-shadow: none !important;
}

.van-nav-bar__content {
    box-shadow: none !important;
}

.van-hairline--bottom:after {
    border-bottom-width: 0 !important;
}

.van-collapse-item {
    border: 1px red solid;
}

.van-popup {
    // position: absolute !important;
}

.van-dropdown-menu__title {
    line-height: normal !important;
}

.van-dropdown-menu__bar {
    height: 60px !important;
}

.van-nav-bar__left {
    padding: 0rem 0.3rem 0rem 0.3rem !important;
}

.van-ellipsis {
    font-weight: bold !important;
    overflow: visible;
}

.van-address-item {
    padding: 25px !important;
}

.van-address-item__name {
    font-size: 32px !important;
}

.van-address-item__tag {
    font-size: 24px !important;
    border-radius: 15px !important;
}

.van-address-item__address {
    line-height: 32px !important;
}

.van-safe-area-bottom {
    // height: 150px !important;
    display: flex;
    align-items: center;
}

.van-address-list__add {
    height: 100px !important;
}

.van-button--round {
    height: 100px !important;
}

.van-cell {
    font-size: 32px !important;
    // margin-top: 32px !important;
}

.van-picker__toolbar {
    margin-top: 10px !important;
}

.van-haptics-feedback {
    font-size: 32px !important;
}

.van-ellipsis {
    font-size: 32px !important;
}

.noticeWidth {
    width: 30% !important;
    font-size: 32px !important;
}

.addcrd {
    .van-checkbox__label {
        line-height: normal;
        margin-left: 15px !important;
    }

    .van-radio__label {
        line-height: normal;
    }

    .van-swipe-cell__right {
        top: -20px;
    }
}

.van-dialog {
    width: 50% !important;
}

.van-dialog__header {
    font-size: 32px !important;
}

.van-dialog__message--has-title {
    font-size: 32px !important;
}

.van-button__text {
    font-size: 32px !important;
}

.van-dialog__message--has-title {
    padding-top: 32px !important;
}

.van-button--large {
    height: 80px !important;
}
</style>
